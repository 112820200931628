export default {
  primary: {
    backgroundColor: 'primary',
    color: 'light',
    border: 'solid 2px',
    borderColor: 'primary',
    borderRadius: '100px',
    fontSize: ['0.9rem', '1rem', '1.25rem'],
    fontWeight: 'bold',
    padding: '1rem 2rem',
    ':hover': {
      backgroundColor: 'transparent',
      color: 'primary'
    }
  },
  secondary: {
    backgroundColor: 'transparent',
    color: 'primary',
    border: 'solid 2px',
    borderColor: 'primary',
    ':hover': {
      backgroundColor: 'primary',
      color: 'white'
    }
  }
}
