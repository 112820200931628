export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.container': {
      padding: '0.5rem',
      backgroundColor: 'white',
      position: 'static'
    },
    '.containerScrolled': {
      backgroundColor: 'white',
      padding: '0.5rem',
      height: 'auto'
    },

    '.logoLocationContainer': {
      position: ['static', 'static', 'static', 'static'],
      margin: '0rem auto 0rem 0rem',
      padding: '0rem',
      a: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '.image': {
          filter: 'unset',
          maxHeight: ['33px', '40px', '', '45px', '55px'],
          height: 'auto'
        }
      }
    },

    '.hamburger': {
      border: 'none',
      padding: ['0.8rem 0.4rem', '0.75rem'],
      margin: '0rem',
      // backgroundColor: 'black',
      minWidth: '40px'
    },

    '.smallNavMenu': {
      '> div': {
        borderLeft: '1px solid lightgrey'
      },
      '.navItem': {
        padding: '0rem 1rem',
        margin: '0rem',
        a: {
          color: 'dark',
          fontSize: '0.9rem',
          fontFamily: 'heading',
          fontWeight: '200',
          letterSpacing: '0px',
          ':hover': {
            color: 'grey'
          }
        }
      }
    },

    '.navMenuLogo': {
      '.image': {
        maxHeight: ['40px', '45px', '', '55px']
      },
      marginBottom: '1rem'
    },

    '.navMenu': {
      width: ['90%', '75%', '50%', ' 35%'],
      transform: 'unset',
      right: '0rem'
    },
    '.navMenuOpen': {
      width: ['90%', '75%', '50%', ' 35%'],
      backgroundColor: 'white',
      alignItems: 'flex-start',
      '.seperatorLine': {
        display: 'none'
      },
      '.navItem': {
        width: '100%',
        a: {
          display: 'flex',
          padding: ['', '', '', '1rem'],
          fontSize: ['1.1rem', '1.25rem'],
          transition: 'all ease-in-out 0.25s',
          color: 'dark',
          fontFamily: 'heading',
          ':hover': {
            color: 'grey'
          }
        }
      }
    },

    '.navBlock': {
      right: '0rem',
      top: '-200vh',
      backgroundColor: 'rgb(70 70 70 / 86%)'
    },
    '.navBlockOpen': {
      backgroundColor: 'rgb(70 70 70 / 86%)'
    },

    '.phoneSocialContainer': {
      marginTop: '1rem',
      alignItems: 'flex-start',
      padding: '0rem 1.5rem',
      '.smallNavHeading ': {
        textAlign: 'left',
        fontFamily: 'body',
        width: '100%'
      },
      '.phoneContainer, .socialIconsContainer, .phoneContainer a': {
        alignItems: 'flex-start',
        justifyContent: 'flex-start'
      },
      a: {
        color: 'dark',
        fontSize: '1rem'
      },
      svg: {
        width: '20px',
        height: '20px',
        path: {
          fill: 'dark'
        }
      }
    }
  },

  footer: {
    backgroundColor: '#f6fff2',
    color: 'dark',
    padding: '2rem',
    '.connectColumn': {
      display: 'none'
    },
    '.address': {
      display: 'none'
    },
    '.email': {
      flexWrap: 'wrap',
      display: 'flex',
      svg: {
        mr: '0.5rem'
      },
      '::before': {
        content: "'Customer Service Email:'",
        width: '100%',
        mb: '0.5rem'
      }
    },
    '.phone': {
      flexWrap: 'wrap',
      display: 'flex',
      svg: {
        mr: '0.5rem'
      },
      '::before': {
        content: "'Customer Service Phone:'",
        width: '100%',
        mb: '0.5rem'
      }
    },
    '.logo': {
      display: 'none'
    },
    '.sectionHeading': {
      fontFamily: 'body'
    },
    '.aboutText': {
      color: 'dark'
    },
    '.footerSectionHeading': {
      color: 'primary',
      borderBottom: '1px solid',
      borderColor: 'primary'
    },
    '.gonationLogo svg': {
      fill: 'dark'
    },
    '.multiButtonContainer': {
      flexDirection: ['', '', '', 'column'],
      a: {
        width: ['', '', '', '100%'],
        color: 'dark',
        ':hover': {
          opacity: '0.7',
          backgroundColor: 'transparent'
        }
      }
    }
  },

  ctaWidget: {
    a: {
      borderRadius: ['', '', '100px']
    }
  },
  loadingPage: {
    backgroundColor: 'secondary'
  },

  // ? ====================
  // ? ===  reuseables  ===
  // ? ====================

  sideBySide1: {
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },
  sideBySide2: {
    variant: 'customVariants.sideBySide1',
    '.content': {
      order: ['', '', '2']
    }
  },

  title: {
    borderLeft: 'none',
    paddingLeft: '0rem',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    fontSize: ['2rem', '', '3rem'],
    fontFamily: 'body',
    marginBottom: '1rem',
    textAlign: 'left'
  },
  subtitle: {
    color: 'primary',
    fontSize: ['1.25rem', '1.5rem', '2rem'],
    fontWeight: '500',
    marginBottom: '1rem',
    textAlign: 'left',
    width: 'fit-content'
  },

  text: {},

  sponsorshipButton: {
    backgroundColor: '#e9f7fb',
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['1.5rem', '1.75rem', '2rem']
    },
    backgroundColor: 'backgroundSecondary',

    button: {
      variant: 'buttons.primary'
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageSponsorshipButton: {
    backgroundColor: 'backgroundSecondary',
    padding: ['3rem 2rem', '', '3rem'],
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['1.5rem', '1.75rem', '2rem']
    },
    '#scriptWidget': {
      display: 'none'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  homepageShout: {
    padding: ['0.5rem', '1rem'],
    backgroundColor: '#263544',
    flexDirection: ['row', 'row', 'row'],

    // shoutContainer
    '.content': {
      // left: ['0rem', '', '2rem'],
      width: ['65%', '75%', '75%', '75%'],
      padding: ['', '', '1rem 2rem'],
      backgroundColor: '#263544',
      color: 'light'
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['1.5rem', '1.75rem', '2rem']
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text',
      maxWidth: 'unset',
      fontSize: ['1.25rem', '1.5rem'],
      marginBottom: '0rem'
    },
    '.date': {
      order: '4',
      margin: '1rem 0rem 0rem'
    },
    '.shoutCTA': {
      variant: 'buttons.primary'
    },
    '.imageContainerBlock': {
      width: ['35%', '25%', '25%', '25%'],
      img: {
        objectFit: 'cover'
      }
    }
  },

  titleBlock: {
    padding: ['4rem 1rem', '6rem 1rem'],
    // background: 'unset',
    height: '100vh',
    backgroundAttachment: ['unset', 'unset', 'unset', 'unset'],
    '.section': {
      alignItems: 'flex-start',
      textAlign: 'left',
      margin: '0rem'
    },
    '.title': {
      fontFamily: 'body',
      textTransform: 'uppercase',
      fontSize: ['3.5rem', '', '7rem'],
      width: ['80%', '', '90%'],
      textAlign: 'left',
      fontWeight: 'bolder',
      color: 'white',
      letterSpacing: '-2px',
      lineHeight: '0.8',
      margin: '0rem'
    },
    '.subtitle': {
      fontFamily: 'body',
      textTransform: 'uppercase',
      fontWeight: 'bolder',
      fontSize: '5rem',
      color: 'rgba(0,28,84,1)',
      order: '2',
      letterSpacing: '-2px',
      lineHeight: '0.8',
      margin: '0rem'
    }
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1'
  },
  homepageK9: {
    variant: 'customVariants.sideBySide2'
  },
  homepageSponsorship: {
    variant: 'customVariants.sideBySide1'
  },
  homepagePresented: {
    '.hero_content_container': {
      color: 'white',
      top: 'unset',
      bottom: '2rem',
      transform: 'unset',
      '.title': {
        variant: 'customVariants.title'
      }
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    '.title': {
      variant: 'customVariants.title',
      textAlign: ['left', '', 'center']
    }
  },

  // ? ===============================
  // ? =====  Sponsorship page  ======
  // ? ===============================

  sponsorshipOptions: {
    '.box': {
      width: ['calc(100% - 2rem)', '', 'calc(50% - 2rem)', 'calc(50% - 2rem)'],
      padding: '2rem 1.5rem,',
      margin: '1rem',
      backgroundColor: 'backgroundSecondary',
      borderRadius: '20px',
      minHeight: '240px'
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['1.2rem', '1.2rem', '1.2rem', '1.2rem'],
      borderBottom: '2px solid',
      padding: '1rem 0.5rem',
      mb: '1.5rem'
    },
    '.subtitle': {
      opacity: '0.8'
    },
    '.ctaLink': {
      variant: 'buttons.primary',
      m: 'auto 1rem 1rem auto'
    }
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumPhotos': {
      // This is already grid. i want 4 columns
      gridTemplateColumns: ['repeat(1, 1fr)', '', '', 'repeat(2, 1fr)'],
      // also auto rows
      //   make rows at least 200px tall
      gridAutoRows: ['unset', '', '', '300px']
    },
    '.albumImage': {
      objectFit: 'contain', // This will ensure the image covers the entire content box
      objectPosition: 'center',
      gridColumnEnd: 'span 1'
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.eventItemImage': {
      minHeight: 'unset'
    },
    '.eventDays, .eventDate': {
      color: 'black'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '4',
    backgroundColor: 'backgroundSecondary',
    '.title': {
      variant: 'customVariants.title',
      textAlign: ['center', '', 'center']
    },
    '.subtitle': {
      fontSize: ['1rem', '', '', '1.25rem'],
      margin: '1rem 0rem',
      opacity: '0.8'
    },
    'form input, form textarea': {
      border: 'none',
      borderBottom: '1px solid grey',
      borderRadius: '0px'
    },
    'form .submitBtn': {
      variant: 'buttons.primary'
    }
  },

  locationMap: {
    order: '3',
    h3: {
      textTransform: 'uppercase',
      fontSize: ['1.25rem', '', '', '1.75rem']
    }
  }
}
